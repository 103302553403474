import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../Home.css';
import Header from '../Header';
import '../Signup.css';
import { useAuth } from '../store/auth';
import Footer from '../Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { storeTokenInLS } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = async (event) =>{
    event.preventDefault();

    try {
      const response = await axios.post('https://api.hirdayam.com/api/login', {
        email,
        password,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      
      console.log('Login successful with data:', response.data);
      
      storeTokenInLS(response.data.data.token);

      setSuccess('Login successful!');
      setError('');
      
     
      navigate('/');
      window.location.reload(); // Refresh the page

      setEmail('');
      setPassword('');
    } catch (error) {
      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
        setError(`Login failed: ${error.response.data.message || 'Unknown error'}`);
      } else if (error.request) {
        console.error('Error request:', error.request);
        setError('Login failed: No response from server.');
      } else {
        console.error('Error message:', error.message);
        setError( error.message);
      }
      setSuccess('');
    }
  };

  return (
    <>
      <Header />
      <div className="login-form-container mt-24 md:mt-10 mx-auto max-w-md">
  <h2 className="login-heading text-center text-2xl font-semibold">Login</h2>
  <h2 className="login-heading1 text-center text-gray-600 mb-4">Please login using account details below.</h2>

  <form className="login-form" onSubmit={handleSubmit}>
    <div className="input-container mb-4">
      <input
        type="email"
        id="email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter Email Address"
        required
        className="w-full p-3 border rounded-md"
      />
    </div>

    <div className="input-container mb-8 relative flex items-center">
      <input
        type={showPassword ? 'text' : 'password'}
        id="password"
        name="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        required
        className="w-full p-3 border rounded-md pr-12" // Added padding for icon space
      />
      <span
        className=" absolute right-[-5rem] md:left-9 top-4 cursor-pointer text-gray-500 hover:text-gray-700"
        onClick={() => setShowPassword(!showPassword)}
      >
        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
      </span>
      <span className="forgot-password absolute right-3 md:right-4 top-12 text-sm hover:underline">
        <Link to="/forgot">Forgot Password?</Link>
      </span>
    </div>

    {/* <div className="button-container mb-4">
      <button type="submit" className="login-button w-full py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700">
        Login
      </button>
    </div>

    <h1 className="create-account text-center text-sm text-gray-600">
      <Link to="/signup" className="text-blue-500 hover:underline">Create Account</Link>
    </h1> */}
    <div className="button-container">
            <button type="submit" className= "login-button">Login</button>
          </div>
          {/* <h1 type="button" className="create-account ml-12 md:ml-20 ">
            <Link to="/signup">Create Account</Link>
          </h1> */}
           <h1 className="create-account text-center ml-8 md:ml-4">
      <Link to="/signup" >Create Account</Link>
    </h1>
  </form>

  {error && <p className="error-text text-red-500 text-center mt-4">{error}</p>}
  {success && <p className="success-text text-green-500 text-center mt-4">{success}</p>}
</div>

      <Footer/>
    </>
  );
};

export default Login;
